<template>
    <div class="topup">
        <div class="head">
            <div class="icon back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">Top Up</div>
        </div>

        <div class="main">
            <div class="input">
                <div class="label">Amount</div>
                <input type="text" placeholder="Enter amount" v-model="amount" 
                    :disabled="loading ? true : false">
            </div>
            <div class="bottom">
                <loading :height="'43px'" v-if="loading"/>
                <div class="btn" v-else >Topup</div>
                <!-- <div class="btn" v-else @click="topup()">Topup</div> -->
            </div>
        </div>
    </div>
</template>

<script>
import back from '../../../icons/back.vue'

import loading from '../../../components/loading.vue'
export default {
    components: {
        loading,
        back
    },
    data() {
        return {
            loading: false,
            amount: '',
            paymentData: {
                tx_ref: '',
                amount: "0",
                currency: "NGN",
                payment_options: "",
                redirect_url: "",
                meta: {
                    username: this.$root.$data.username
                },
                customer: {
                    name: this.$root.$data.firstname + ' ' + this.$root.$data.lastname,
                    email: this.$root.$data.email
                },
                customizations: {
                    title: "Ikotun Connect Wallet Topup",
                    description: "",
                    logo: "https://ikotunconnect.com.ng/api/images/ikotun_logo_2.png"
                },
                callback: this.makePaymentCallback,
                onclose: this.closedPaymentModal
            }
        }
    },
    methods: {
        generateReference(){
            let date = new Date();
            return date.getTime().toString() + Math.floor(Math.random() * 10000);
        },
        back() {
            this.$router.go(-1)
        },
        topup() {
            if (this.amount == '' || parseFloat(this.amount) < 1) {
                this.amt_err(); return
            }

            this.paymentData.tx_ref = this.generateReference()

            this.loading = true

            let amount = this.amount

            let form = new FormData()
            form.append('amount', amount)
            form.append('tx_ref', this.paymentData.tx_ref)

            this.$http.post('/wallet/topup/init', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    let transaction = {
                        'username': this.$root.$data.username,
                        'status': 'pending',
                        'flow': 'in',
                        'source': 'flutterwave',
                        'category': 'topup',
                        'type': 'deposit',
                        'ref': this.paymentData.tx_ref,
                        'amount': amount
                    }
                    this.$root.$data.new_transaction = transaction

                    this.paymentData.payment_options = r.data.payment_options
                    this.paymentData.amount = amount
                    
                    this.payViaService()
                } else {
                    if (r.not_active) {
                        this.not_active()
                    }
                }

                this.loading = false
            })
        },
        payViaService() {
            
            this.$payWithFlutterwave(this.paymentData);
        },
        makePaymentCallback(response) {
            console.log("Pay", response);

            let form = new FormData()
            form.append('tx_id', response.transaction_id)
            form.append('ref', this.paymentData.tx_ref)

            this.$http.post('/wallet/topup/complete', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    console.log(r);
                }
            })
        },
        closedPaymentModal() {
            console.log('flutter closed');
        }
    },
    notifications: {
        amt_err: {
            type: 'warn',
            title: '',
            message: 'Please enter a valid amount'
        },
        not_active: {
            type: 'info',
            title: '',
            message: 'Wallet topping up is current not avaliable, try again later'
        }
    }
}
</script>

<style scoped>
    
    .topup {
        background-color: white;
    }
    .head {
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
        position: relative;
    }
    .icon {
        position: absolute;
        top: 0px;

        height: 50px;
        padding: 0px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: var(--trans);
    }
    .back {
        left: 0px;
    }
    .title {
        font-weight: 500;
        text-align: center;
    }

    .main {
        height: calc(100vh - 50px);
        padding: 20px;
        overflow: scroll;
        box-sizing: border-box;
        position: relative;

    }
    .input {
        position: relative;
        margin-top: 10px;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        background-color: white;
        color: gray;
        font-size: 14px;

        padding: 0px 5px;
    }
    input {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }


    .bottom {

        position: absolute;
        bottom: 20px;
        width: calc(100% - 40px);
    }
    .btn {
        background-color: var(--main);
        padding: 12px;
        box-sizing: border-box;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
        text-align: center;

        /* left: 20px; */
        /* margin: 20px; */
    }
</style>